<template>
    <div class="container">
        <Toolbar
            :click-add="showAdd"
            :click-edit="showEdit"
            :click-delete="showDelete"
            :click-refresh="showRefresh"
            :set-filter="loadDataSearch"
            :status-print="false"
            :data   = "recs"
            :fields = "json_fields"
            type    = "xls"
            name    = "Gudang.xls"
        />
        <div class="container-fluid p-0">
            <div id="wrapper">
                <div id="content">
                    <div class="w3-hide-small w3-hide-medium" id="boundary">
                        <table id="header" cellpadding="0" cellspacing="5" border="0" class="table table-hover table-strip">
                            <thead>
                                <tr class="">
                                    <th width="50px">No. </th>
                                    <th v-for="fl in filterByShow" :width="fl.width" :key="fl.name" class="h9 text-center">{{fl.name}}</th>
                                        <div v-if="adminOnly()">
                                    <th width="50px text-centar">App</th>
                                        </div>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-210) +'px;'">
                        <table class="table table-hover table-strip table-bordered col-9">
                            <tbody>
                                <tr v-for="(dt, index) in recs" :key="dt.per_id" class="w3-hover-pale-blue w3-small cursor" :class="index == selectedRow ?'w3-pale-blue':''">
                                    <td width="50px" class="text-center">
                                        <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>&nbsp;{{dt.idx}}
                                        <a href="#" class="text-base" @click="openModalDtl(dt.per_id,dt.per_desc)"><i class="fa fa-plus"></i></a>
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','per_id')" class="w3-hide-small w3-hide-medium">
                                        {{dt.per_id}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','k_name')" class="w3-hide-small w3-hide-medium">
                                        {{dt.k_name}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','per_pelapor')" class="w3-hide-small w3-hide-medium">
                                        {{dt.per_pelapor}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','per_pic')" class="w3-hide-small w3-hide-medium">
                                        {{dt.per_pic}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'max-width:'+findObjFind('width','per_desc')" class="text-truncate w3-hide-small w3-hide-medium">
                                        {{dt.per_desc}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','per_start')" class="w3-hide-small w3-hide-medium">
                                        {{dt.per_start}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','per_end')" class="w3-hide-small w3-hide-medium">
                                        {{dt.per_end}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','per_status')" class="w3-hide-small w3-hide-medium">
                                        <span v-html="setStatus(dt.per_status)"></span>
                                    </td>
                                    <div v-if="adminOnly()">
                                        <td style="width: 25px;">
                                            <a v-show="index == selectedRow && dt.per_status == 3" class="btn btn-success btn-sm" v-on:click="Approved(dtA, index)"><i class="mdi mdi-record-circle" ></i>
                                            </a>
                                        </td>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- pagging -->
        <nav class="Page navigation h9 bg-light bg-gradient shadow">
            <div class="d-flex justify-content-center">
                <ul class="pagination text-center">
                    <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="first()" href="#">First</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="prev()" href="#">«</a></li>
                    <li class="page-item page-item active"><a class="page-link px-0 px-md-2" href="#">Page {{pages.halaman}} sd {{pages.ttlhalaman}}</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="next()" href="#">»</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="last()" href="#">Last</a></li>
                    <li class="page-item page-item active"><a class="page-link  page-item active" href="#">Rec {{pages.ttlrec}}</a></li>
                    </ul>
            </div>
        </nav>
        <div id="modal-form" class="modal row mt-4 top-50 start-50 translate-middle ms-md-0 ms-1 modal-md">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="w3-animate-zoom modal-content">
            <div class="modal-header pb-0">
                <button onclick="document.getElementById('modal-form').style.display='none'"
                class="btn w3-display-topright btn-lg">&times;</button>
                <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Add / Edit data</p>
            </div>
            <div class="modal-body">
                <form class="row px-3" id="frmGdg" v-on:submit.prevent="saveData" autocomplete="off">
                    <div class="mb-3 col-md-6">
                            <label for="per_id" class="form-label h9 fw-bold">{{findObjFind('name','per_id')}} :</label>
                            <input ref="per_id" readonly type="text" disabled class="form-control" id="per_id" :placeholder="findObjFind('name','per_id')" v-model="rec.per_id">
                    </div>
                    <div class="mb-3 col-md-6">
                            <label class="form-label h9 fw-bold">{{findObjFind('name','k_name')}} :</label>
                            <model-list-select

                                :list="kendaraans"
                                class="w3-small"
                                option-value="k_id"
                                option-text="k_name"
                                v-model="rec.k_id"
                                placeholder="Pilih Kendaraan"

                            ></model-list-select>
                    </div>
                    <div class="mb-3 col-md-6">
                            <label for="per_pelapor" class="form-label h9 fw-bold">{{findObjFind('name','per_pelapor')}} :</label>
                            <input ref="per_pelapor" type="text" class="form-control" id="per_pelapor" :placeholder="findObjFind('name','per_pelapor')" v-model="rec.per_pelapor">
                    </div>
                    <div class="mb-3 col-md-6">
                            <label for="per_pic" class="form-label h9 fw-bold">{{findObjFind('name','per_pic')}} :</label>
                            <input ref="per_pic" type="text" class="form-control" id="per_pic" :placeholder="findObjFind('name','per_pic')" v-model="rec.per_pic">
                    </div>
                    <div class="mb-3">
                            <label for="per_desc" class="form-label h9 fw-bold">{{findObjFind('name','per_desc')}} :</label>
                            <textarea ref="per_desc" type="text" class="form-control" id="per_desc" :placeholder="findObjFind('name','per_desc')" v-model="rec.per_desc"></textarea>
                    </div>
                    <div class="mb-3 col-md-6">
                            <label for="per_start" class="form-label h9 fw-bold">{{findObjFind('name','per_start')}} :</label>
                            <input ref="per_start" type="datetime-local" class="form-control" id="per_start" :placeholder="findObjFind('name','per_start')" v-model="rec.per_start">
                    </div>
                    <div class="mb-3 col-md-6">
                            <label for="per_end" class="form-label h9 fw-bold">{{findObjFind('name','per_end')}} :</label>
                            <input ref="per_end" type="datetime-local" class="form-control" id="per_end" :placeholder="findObjFind('name','per_end')" v-model="rec.per_end">
                    </div>

                    <div class="row align-items-center">
                        <div class="mb-3 col-7">
                            <label class="form-label h9 fw-bold">Pilih Item :</label>
                            <model-list-select
                                :list="invs"
                                class="w3-small"
                                option-value="pem_id"
                                option-text="pem_deskripsi"
                                v-model="rei.pem_id"
                                placeholder="Pilih Sparepart"
                                @input="handleSelectChange"
                            ></model-list-select>
                            <input type="text" hidden class="" v-model="qty">
                        </div>
                        <div class="mb-3 col-3">
                            <label for="pem_qty" class="form-label h9 fw-bold">QTy :</label>
                            <input ref="pem_qty" type="text" class="form-control" id="pem_qty" v-model="rei.qty" @input="handleManualQtyChange">
                        </div>
                        <div class="col-2 pt-3">
                            <a class="btn btn-success" @click="handleSubmit"><i class="fa fa-plus"></i></a>
                        </div>

                    </div>

                    <div class="w3-row">
                        <table class="table table-hover table-strip table-bordered col-9">
                            <thead>
                                <tr class="">
                                    <th class="w3-border-right">Item</th>
                                    <th class="w3-border-right w3-center" width="120px">Qty</th>
                                    <th width="30px">
                                        aksi
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(dtIt, index) in rex" :key="index">
                                    <td class="w3-border-right w3-small">
                                        <input type="text" hidden class="form-control" v-model="dtIt.pem_id">
                                        <input type="text" hidden class="form-control" v-model="dtIt.pei_id">
                                        <b>{{dtIt.pem_deskripsi}}</b>
                                    </td>
                                    <td class="w3-border-right">
                                        <input type="text" :readonly="stsAdd" class="w3-input w3-small" v-model="dtIt.pei_qty" >
                                    </td>
                                    <td>
                                        <a type="button" class="w3-btn w3-tiny w3-red w3-round-large" @click="removeItem(index)">
                                            <i class="fa fa-remove"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>

                </form>
            </div>
            <div class="modal-footer"  style="margin-top: 15px;">
                <h6 class="w3-right">
                <button form="frmGdg" class="btn btn-success rounded" type="submit"><i class="fa fa-save"></i> Save</button>
                <button type="button" class="btn btn-danger ms-2 rounded" onclick="document.getElementById('modal-form').style.display='none'"><i class="fa fa-close"></i> Close</button>
                </h6>
            </div>
            </div>
            </div>
        </div>

        <div id="modal-dtl" class="modal row mt-4 top-50 start-50 translate-middle ms-md-0 ms-1 modal-md">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="w3-animate-zoom modal-content">
            <div class="modal-header pb-0">
                <button onclick="document.getElementById('modal-dtl').style.display='none'"
                class="btn w3-display-topright btn-lg">&times;</button>
                <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Detail Sparepart {{ idper }}</p>
            </div>
            <div class="modal-body">
                <div class="p-2" style="max-height: 250px;">
                    <b>Deskripsi :</b><br>
                <p class="mt-auto text-wrap" >
                    {{ desc }}
                </p>
                </div>
                <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Nama Item</th>
                        <th scope="col">Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(dt,index) in itemdtl" :key="dt.pei_id" class="w3-hover-pale-blue w3-small cursor">
                            <td>{{ index + 1 }}</td>
                            <td>{{dt.pem_deskripsi}}</td>
                            <td>{{dt.pei_qty}}</td>
                          </tr>
                    </tbody>
                  </table>
                  <div v-if="!itemdtl.length">Tidak ada sparepart yang di pakai</div>
            </div>
            <div class="modal-footer"  style="margin-top: 15px;">
                <h6 class="w3-right">
                <button type="button" class="btn btn-danger ms-2 rounded" onclick="document.getElementById('modal-dtl').style.display='none'"><i class="fa fa-close"></i> Close</button>
                </h6>
            </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
/* eslint-disable */
import Config from '@/conf/Config.js';
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import Toolbar from "@/components/Toolbar.vue";
import { ModelListSelect } from "vue-search-select";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Perbaikan',
    components: {
        Toolbar,
        ModelListSelect
    },
    data() {
        return {
            screenHeight : 0,
            selectedRow : -1,
            DatePickerFormat: 'YYYY-MM-DD',
            rec: {},
            qty: '', // Variabel untuk menyimpan nilai qty
            qty2: '',
            idper: '',
            desc: '',
            recs: [],
            rei:{},
            rex:[],
            showModalDtl: true,
            itemdtl: [],
            kendaraans: [],
            invs: [],
            itemt: [],
            recItems : [],
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, limit:Config.perpage, search:''},
            filters : {
            	operator : 'AND',
                fields :[
                        {fieldFind : '', key : 'per_id', type: 'text', name : 'ID', filter : true, width : '150px', grid : true},
                        {fieldFind : '', key : 'k_name', type: 'text', name : 'Kendaraan', filter : true, width : '150px', grid : true},
                        {fieldFind : '', key : 'per_pelapor', type: 'text', name : 'Pelapor', filter : true, width : '100px', grid : true},
                        {fieldFind : '', key : 'per_pic', type: 'text', name : 'Mekanik', filter : true, width : '100px', grid : true},
                        {fieldFind : '', key : 'per_desc', type: 'text', name : 'Deskripsi', filter : true, width : '250px', grid : true},
                        {fieldFind : '', key : 'per_start', type: 'text', name : 'Tanggal Lapor', filter : true, width : '150px', grid : true},
                        {fieldFind : '', key : 'per_end', type: 'text', name : 'Tanggal Selesai', filter : true, width : '150px', grid : true},
                        {fieldFind : '', key : 'per_status', type: 'text', name : 'Status', filter : true, width : '60px', grid : true},
                ],
                find : []
            },
            /**Untuk transfer excel*/
            json_fields : {
                'ID' : 'g_id',
                'NAME' : 'g_name',
                'KETERANGAN' : 'g_keterangan'
            },
        }
    },
    watch: {
        recs: {
            handler: 'loadData', // calls the loadData method when recs changes
            deep: true // watches changes deeply within the array
        }
    },
    methods : {
        adminOnly() {
      const userLevel = localStorage.getItem("gudang");
      return userLevel === "admin";
        },
        findObjFind(key, val){
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        handleSelectChange() {
            const selectedInventory = this.invs.find(inv => inv.pem_id === this.rei.pem_id);

            if (selectedInventory) {
                this.qty = selectedInventory.pem_qty;

                const itemsWithSameId = this.itemt.filter(item => item.pem_id === this.rei.pem_id);
                const totalQty = itemsWithSameId.reduce((total, item) => total + parseInt(item.pei_qty), 0);

                // Menambahkan totalQty ke this.qty
                this.qty -= totalQty;

                // Handle jika qty negatif (optional)
                if (this.qty < 0) {
                    this.qty = 0; // Atau beri pesan error atau lakukan penanganan lain sesuai kebutuhan aplikasi Anda
                }
            } else {
                this.qty = null;
            }
        },
        handleManualQtyChange() {
            const parsedQty = parseFloat(this.qty);
            const reiQty = parseFloat(this.rei.qty);

            if (reiQty > parsedQty) {
                alert("Masukan Qty yang sesuai");
                this.rei.qty = this.qty;
            }
        },

        handleSubmit() {
        // Validasi jika qty di rei lebih besar dari qty di inventaris
        if (parseFloat(this.rei.qty) > parseFloat(this.qty)) {
            alert("Qty tidak dapat melebihi jumlah di inventaris.");
            return;
        }

        if (parseFloat(this.rei.qty) <= 0 || isNaN(parseFloat(this.rei.qty))) {
            alert("Qty tidak boleh 0");
            return;
        }

        // Cari inventaris yang dipilih
        const selectedInventory = this.invs.find(inv => inv.pem_id === this.rei.pem_id);

        // Simpan nilai rei.qty sebelum menguranginya dari inventaris
        const reiQty = parseFloat(this.rei.qty);

        // Kurangi qty di inventaris sesuai dengan rei.qty
        if (selectedInventory) {
            selectedInventory.pem_qty -= reiQty;
        }

        // Dapatkan pem_deskripsi dari invs
        const pemDeskripsi = selectedInventory.pem_deskripsi;

        // Tambahkan this.rei ke dalam this.rex bersama pem_deskripsi
        this.rex.push({
            pem_id: this.rei.pem_id,
            pei_qty: reiQty, // Gunakan nilai reiQty yang sudah disimpan
            pem_deskripsi: pemDeskripsi,
            pfunction : 'add',
            userentry : localStorage.username,
            per_id: this.rec.per_id
        });

        // Reset nilai this.rei
        this.rei = {
            pem_id: null,
            qty: null
        };

    },
        removeItem(index) {
            const confirmed = window.confirm("Apakah Anda yakin ingin menghapus item ini?");
            if (confirmed) {
                const removedItem = this.rex[index];
                axios.post("kendaraan/ApiItem.php", {
                    pfunction: 'delete',
                    pei_id: removedItem.pei_id  // Corrected pei_id value
                });
            // Kembalikan nilai qty ke inventaris
            const selectedInventory = this.invs.find(inv => inv.pem_id === removedItem.pem_id);
            if (selectedInventory) {
                selectedInventory.pem_qty += parseFloat(removedItem.qty);
            }
            this.setInit();
            // Hapus item dari rex
            this.rex.splice(index, 1);
            }
        },
        setStatus(dt) {
            if (dt === "1") {
                return "<div class='btn btn-success btn-sm' >Selesai</span></div>";
            } else {
                return "<div class='btn btn-danger btn-sm'>Proses</span><div>";
            }
        },
        openModalDtl(perId,desc) {
            var self = this;
            axios.post("kendaraan/ApiItem.php", {
                pfunction: 'show',
                per_id: perId
            }).then(function (response) {
                self.itemdtl = response.data.rec;
                document.getElementById("modal-dtl").style.display = "block";
            }).catch(function (error) {
                console.error('Error fetching data:', error);
            });
            self.idper = perId;
            self.desc = desc;

        },

        findField(val){
            return this.findObj(this.fieldRules, 'field',val);
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (var pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        first(){
            let self = this;
            self.pages.halaman = 1;
            self.loadData();
        },
        prev(){
            let self = this;
            self.pages.halaman = self.pages.halaman - 1;
            self.loadData();
        },
        next(){
            let self = this;
            self.pages.halaman = self.pages.halaman + 1;
            self.loadData();
        },
        last(){
            let self = this;
            self.pages.halaman = self.pages.ttlhalaman;
            self.loadData();
        },
        setKlikRow(index, dt){
            let self = this;
            self.selectedRow = index;
            self.rec = Object.assign({}, dt);
        },
        loadData() {
            // Your existing loadData method logic
            this.screenHeight = window.innerHeight;
            let self = this;
            self.selectedRow = -1;
            self.rec = {};
            let params = {
                pfunction:'show',
                limit: self.pages.limit,
                page: self.pages.halaman,
                search: self.pages.search
            };

            axios.post("kendaraan/ApiPerbaikan.php", params)
                .then(function (response) {
                    let idx = (self.pages.limit * self.pages.halaman) - self.pages.limit + 1;
                    self.pages.ttlrec = parseFloat(response.data.ttlrec);
                    self.pages.ttlhalaman = Math.ceil(parseFloat(response.data.ttlrec) / self.pages.limit);
                    response.data.rec.map(function(val){
                        val['idx'] = idx;
                        idx++;
                    });
                    self.recs = response.data.rec;
                });
        },
        showRefresh(){
            this.pages.search = "";
            this.loadData();
        },
        loadDataSearch(filter){
            this.pages.search = filter;
            this.loadData();
        },
        async showAdd() {
            let self = this;
            self.stsAdd = true;

            let key = await axios.post("admin/ApiGetId.php",{
                pfunction : 'getId',
                vc_prefix : 'PER',
                vc_table : 'tb_perbaikan',

            });

            self.rec = {
                per_id : key.data,
                k_id : '',
                per_pelapor : '',
                per_pic : '',
                per_desc : '',
                per_start : '',
                per_end : ''
            };
            self.rex = [];
            document.getElementById("modal-form").style.display = "block";
            setTimeout(function(){
                document.getElementById('per_id').focus();
            },500);
        },
        showEdit(){
            let self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('pilih tiket.!');
                return false;
            }else{
                this.stsAdd = false;
                document.getElementById('modal-form').style.display='block';
                axios.post("kendaraan/ApiItem.php",{
                pfunction : 'show',
                per_id : self.rec.per_id
                    }).then(function (response) {
                        self.rex = response.data.rec;
                    });
                setTimeout(function(){
                    document.getElementById('per_id').focus();
                },500);
            }
        },
        Approved(idx) {
            let self = this;
            swal(
                {
                    title: "Yakin untuk Approved Perbaikan ?",
                    text: "Data Yang sudah di Approved tidak bisa di unapproved",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Approved Perbaikan",
                    cancelButtonText: "Tidak, Batal Approved",
                    closeOnConfirm: false,
                    closeOnCancel: true,
                },
                function(isConfirm) {
                    if (isConfirm) {
                        let vparams = {
                            per_id : self.rec.per_id,
                            pfunction : "approved"
                        };
                        axios.post("kendaraan/ApiPerbaikan.php",vparams).then(function (response) {
                            if (response.data.success == true) {
                                self.$set(self.recs,idx);
                                swal({title: "Approved",text: "Approved Berhasil",timer: 1000,type: "success"});
                            }else{
                                swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                            }
                        });
                    }
                }
            );
        },
        saveData(event) {
            let self = this;

            if (self.stsAdd == true) {
                self.rec.pfunction = "add";
            } else {
                self.rec.pfunction = "edit";
            }

            axios.post("kendaraan/ApiPerbaikan.php", self.rec)
                .then(function (response) {
                    if (response.data.success == true) {
                        self.$toast.success(response.data.msg);

                        // If it's an edit, update the existing record in recs array
                        if (self.rec && self.rec.pfunction === 'edit') {
                            // Corrected usage of $set
                            self.$set(self.recs, self.recs.indexOf(self.rec), response.data.updatedRecord);
                        } else {
                            // If it's an add, reload the data to get the latest changes
                            self.loadData();
                        }

                        self.saveItems();
                        self.setInit();
                        document.getElementById('modal-form').style.display = 'none';
                    } else {
                        self.$toast.error(response.data.msg);
                    }
                });

            event.preventDefault();
            return false;
        },
        saveItems() {
            let self = this;
            self.rex.map(function(key) {
                axios.post("kendaraan/ApiItem.php",key).then(function (response) {
                    if(response.data.success == true){
                        console.log("Sukses", response.data.messages);

                    } else {
                        console.log("Gagal", response.data);
                    }
                });
            });
        },
        showDelete() {
            var self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('pilih Laporan.!');
                return false;
            }else{
                swal(
                    {
                        title: "Yakin Ingin Menghapus Data ..!",
                        text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Ya, Hapus Data",
                        cancelButtonText: "Tidak, Batal Hapus",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                    },
                    function(isConfirm) {
                        if (isConfirm) {
                            var vparams = {
                                per_id : self.rec.per_id,
                                pfunction : "delete"
                            };
                            axios.post("kendaraan/ApiPerbaikan.php",vparams).then(function (response) {
                                if (response.data.success == true) {
                                    swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                                    self.$delete(self.recs, self.selectedRow);
                                }else{
                                    swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                                }
                            });
                        }
                    }
                );
            }
        },
        setInit() {
            let self = this;
            axios.post("master/ApiKendaraan.php",{
                pfunction : 'showall'
            }).then(function (response) {
                self.kendaraans = response.data.rec;
            });
            axios.post("invent/ApiInv.php",{
                pfunction : 'showi',
                dept : '3'
            }).then(function (response) {
                self.invs = response.data.rec;
            });
            axios.post("kendaraan/ApiItem.php",{
                pfunction : 'showall'
            }).then(function (response) {
                self.itemt = response.data.rec;
            });
        },
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
        pNumber(num) {
            var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;
            });
        }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
        this.loadData();
    }
};
</script>